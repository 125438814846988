const wait = () => new Promise(resolve => setTimeout(resolve, 100));

const getAuth = async () => {
  while (window && !window.auth) {
    // eslint-disable-next-line no-await-in-loop
    await wait();
  }
  return window && window.auth;
};

export default getAuth;
