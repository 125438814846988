/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

const headerAuctionProps = PropTypes.arrayOf(PropTypes.shape({
  displayText: PropTypes.string,
  eventDate: PropTypes.string,
  includeLocation: PropTypes.bool,
  includePinterest: PropTypes.bool,
  legacyStyles: PropTypes.bool,
  location: PropTypes.string,
  media: PropTypes.string,
  saleNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}));

const privateSalesMenuProps = PropTypes.arrayOf(PropTypes.shape({
  "title": PropTypes.string.isRequired,
  "displayText": PropTypes.string,
  "url": PropTypes.string.isRequired,
  "media": PropTypes.string,
  "saleNumber": PropTypes.string.isRequired,
  "includeLocation": PropTypes.bool.isRequired,
  "location": PropTypes.string,
  "eventDate": PropTypes.string,
  "includePinterest": PropTypes.bool.isRequired,
  "legacyStyles": PropTypes.bool.isRequired
}));

const notificationBannerProps = {
  "notificationId": PropTypes.string.isRequired,
  "isActive": PropTypes.bool.isRequired,
  "notificationMessage": PropTypes.string.isRequired,
  "notificationAction": PropTypes.string.isRequired,
  "notificationActionUrl": PropTypes.string.isRequired,
  "viewed": PropTypes.bool.isRequired
}
export {
  headerAuctionProps,
  notificationBannerProps,
  privateSalesMenuProps
};