/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { configureStoreWithRouter } from '../utils/configureStore';
import Layout from './Layout';
import getRoutes, { hasLanguageInUrl } from './routes';
import searchOptions from '../ArtistSearch/reducers';
import { language } from '../PhillipsI18N/reducers';
import * as modalReducers from '../PhillipsModal/reducer';
import * as userReducers from '../PhillipsUser/reducers';
import createInitialUserState from '../PhillipsUser/createInitialUserState';
import headerAuctionsReducer from './reducers';
import { headerAuctionProps, privateSalesMenuProps } from './proptypes';

const initialState = {
  modal: {
    show: false,
    type: ''
  },
  searchOptions: []
};

const NavIndex = ({
  activeNode,
  language: lang,
  location,
  userJSON,
  headerAuctions,
  privateSalesMenu,
  notificationBanner,
  sticky
}) => {
  const { routes, options } = getRoutes(location);
  const initialLanguage = hasLanguageInUrl.test(location) ? 'zh' : lang;
  const store = configureStoreWithRouter(
    {
      language,
      searchOptions,
      headerAuctions: headerAuctionsReducer,
      privateSalesMenu: headerAuctionsReducer,
      ...modalReducers,
      ...userReducers
    },
    {
      ...initialState,
      language: initialLanguage,
      headerAuctions,
      privateSalesMenu,
      ...createInitialUserState(JSON.parse(userJSON))
    },
    routes,
    options
  );
  const isHomepage = location.replace(lang, '').trim() === '/';
  return (
    <Provider store={store}>
      <Layout activeNode={activeNode} notificationBanner={notificationBanner} sticky={isHomepage ? sticky : []} />
    </Provider>
  );
};
NavIndex.defaultProps = {
  activeNode: null,
  notificationBanner: null
};
NavIndex.propTypes = {
  activeNode: PropTypes.string,
  headerAuctions: headerAuctionProps.isRequired,
  language: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  notificationBanner: PropTypes.string,
  privateSalesMenu: privateSalesMenuProps.isRequired,
  userJSON: PropTypes.string.isRequired
};

export { NavIndex };
