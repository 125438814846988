import 'fetch-ponyfill';
import apiSettings from '../utils/ApiSettings.decorator';

@apiSettings
class AnalyticsService {
    async trackActivity(data) {
        const url = `${this.apiDomain}api/externalclient/metacapi`;

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error("AnalyticsService failed");
        }
    }
}

export default AnalyticsService