const routes = {
  'ROUTES_DEFAULT': '/',
  'ROUTES_LANGUAGE': '/:language',
  'ROUTES_FILTER': '/filter/:filter',
  'ROUTES_FILTER_LANGUAGE': '/filter/:filter/:language',
  'ROUTES_SORT': '/sort/:sort',
  'ROUTES_SORT_LANGUAGE': '/sort/:sort/:language',
  'ROUTES_FILTERSORT': '/filter/:filter/sort/:sort',
  'ROUTES_FILTERSORT_LANGUAGE': '/filter/:filter/sort/:sort/:language'
};

const getRoutes = (location = '') => {
  const urlSplit = location.split('/').filter(str => str.length > 0);
  return {
    routes,
    options: {
      basename: `/${urlSplit[0]}/${urlSplit[1]}`,
      initialEntries: [location]
    }
  }
}

export default getRoutes;
