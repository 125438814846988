import uriEncoder from '../utils/uriencoder';

export default {
  ROUTES_DEFAULT: {
    path: '/:makerName/:saleNumber/:lotNumberFull',
    toPath: (value, key) => {
      if (key === 'makerName') {
        const capitalized = uriEncoder(value).toLowerCase();
        return capitalized;
      }
      return value;
    }
  },
  ROUTES_NO_MAKER: {
    path: '/:saleNumber/:lotNumberFull'
  },
  ROUTES_LANGUAGE: {
    path: '/:makerName/:saleNumber/:lotNumberFull/:language',
    toPath: (value, key) => {
      if (key === 'makerName') {
        const capitalized = uriEncoder(value).toLowerCase();
        return capitalized;
      }
      return value;
    }
  }
};
