import cookies from 'cookies-js';

export default function ApiSettings(target) {
  Object.defineProperties(target.prototype, {
    apiDomain: {
      get: () => {
        let apiDomain = 'http://localhost:8001/';
        if (localStorage && localStorage.getItem('apiAddress')) {
          apiDomain = localStorage.getItem('apiAddress');
        } else if (window && cookies.get('apiAddress')) {
          apiDomain = cookies.get('apiAddress');
        }
        return apiDomain;
      }
    },
    cmsApiDomain: {
      get: () => {
        let cmsApiDomain = 'http://localhost:8000/';
        if (localStorage && localStorage.getItem('cmsApiAddress')) {
          cmsApiDomain = localStorage.getItem('cmsApiAddress');
        }
        return cmsApiDomain;
      }
    }
  });
}
