import Cookies from 'universal-cookie';

/** Gets the current selected language. */
export const getCurrentLanguage = () => {
  try {
    // get language from cookie
    const cookies = new Cookies();
    if (cookies && cookies.get('phillipsLanguage')) {
      return cookies.get('phillipsLanguage');
    }
  } catch (e) {
    console.error('There was an error getting the current language: ', e, e.stack);
    return "en";
  }
}

/**
 * 
 * @param {object} language languagepack to retrieve from
 */
export const setCurrentLanguage = (language) => {
  try {
    const dateTwoYearsFromNow = new Date();
    dateTwoYearsFromNow.setFullYear(dateTwoYearsFromNow.getFullYear() + 2);
    const cookies = new Cookies();
    cookies.set('phillipsLanguage', language, { path: '/', expires: dateTwoYearsFromNow, sameSite: 'none', secure: true });
    if (location) {
      const pathName = window.location.pathname;
      // do a page reload if on the buysell pages or about privacy
      if (pathName.startsWith('/buysell')
        || pathName.startsWith('/about')
        || pathName.startsWith('/about/privacy')
        || pathName.startsWith('/professional-advisor-services')
        || pathName.startsWith('/sell')
        || pathName.startsWith('/location')) {
        location.reload()
      }
    }
  } catch (e) {
    console.error('There was an error setting the language: ', e);
  }
}