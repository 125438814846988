import camelCase from 'lodash/camelCase';
import siteLabels from '../../../PhillipsPublic.Web/Resources/I18n.json';
import siteTitles from '../../../PhillipsPublic.Web/Resources/I18nForPageTitles.json';

/**
 *
 * @param {object} param
 * @param {string} param.label key value from language pack
 * @param {array} param.args array of values for template
 */
const getI18nLabel = (language = 'en') => ({ label = 'auctions', args = [], defaultValue = undefined, value = '' }) => {
  try {

    if (label.length === 0 && value.length === 0) {
      return 'You must pass a label OR value property';
    }
    const languagePack = siteLabels[language.toUpperCase()] || siteLabels.EN;

    // check value key first
    if (value.length > 0) {
      const valueKey = camelCase(value);
      const valueValue = languagePack[valueKey];
      if (!valueValue) {
        // console.warn(`No label for ${valueKey} in ${language}`);
        return value;
      }
      return valueValue;
    }
    let labelValue = languagePack[label];
    if (!labelValue || labelValue.length === 0) {
      if (typeof defaultValue !== 'undefined') {
        return defaultValue
      }
      // if no value found get it from the english pack
      const languagePackDefault = siteLabels.EN;
      if (!languagePackDefault[label]) {
        return label; // `No label for ${label} in ${language}`;
      }
      labelValue = languagePackDefault[label];
    }
    const isTemplate = /{\d}/g;

    return isTemplate.test(labelValue)
      ? labelValue.replace(isTemplate, (match) => {
        return args[parseInt(match.replace(/[{}]/, ''), 0)];
      })
      : labelValue;
  } catch (e) {
    console.error('There was an error on getI18nLabel: ', e, e.stack);
    return `Error looking for ${label}`;
  }
}

export const getI18nTitle = (language = 'en') => ({ title = 'galleryOne', args = [], defaultValue = undefined, value = '' }) => {
  try {
    if (title.length === 0 && value.length === 0) {
      return 'You must pass a label OR value property';
    }
    const languagePack = siteTitles[language.toUpperCase()] || siteTitles.EN;

    // check value key first
    if (value.length > 0) {
      const valueKey = camelCase(value);
      const valueValue = languagePack[valueKey];
      if (!valueValue) {
        // console.warn(`No title for ${valueKey} in ${language}`);
        return value;
      }
      return valueValue;
    }
    let translatedTitle = languagePack[title];
    if (!translatedTitle || translatedTitle.length === 0) {
      if (typeof defaultValue !== 'undefined') {
        return defaultValue
      }
      // if no value found get it from the english pack
      const languagePackDefault = siteTitles.EN;
      if (!languagePackDefault[title]) {
        return `No title for ${title} in ${language}`;
      }
      translatedTitle = languagePackDefault[title];
    }
    const isTemplate = /{\d}/g;

    return isTemplate.test(translatedTitle)
      ? translatedTitle.replace(isTemplate, (match) => {
        return args[parseInt(match.replace(/[{}]/, ''), 0)];
      })
      : translatedTitle;
  } catch (e) {
    console.error('There was an error on getI18nLabel: ', e, e.stack);
    return `Error looking for ${title}`;
  }
};

const translatedFlocklerIds = [
  '58561057',
  '69138631'
];

export const setI18nLinkLanguage = language => url => {
  const cleanUrl = url === '/' && language === 'en' ? '/' : url.replace(/\/$/g, '');
  if (cleanUrl.indexOf('/article/') > -1) {
    const urlSplit = cleanUrl.split('/').filter(str => str.length > 0);
    const flocklerId = urlSplit[urlSplit.indexOf('article') + 1];
    if (translatedFlocklerIds.indexOf(flocklerId) > -1) {
      switch (flocklerId) {
        case '58561057':
          return language === 'zh'
            ? cleanUrl.replace('58561057/auction-bidding-anywhere', '65563948/bids-anywhere-cn/zh',)
            : `${cleanUrl}`;
        case '69138631':
          return language === 'zh'
            ? cleanUrl.replace('69138631/2021-office-gallery-closures-procedures', '70020146/2021-office-gallery-closures-procedures-cn/zh')
            : `${cleanUrl}`;
        default:
          break;
      }
    }
  }
  
  // separate querystring parameters and put them and the end or the url
  const cleanUrlSplit = cleanUrl.split('?');
  if (cleanUrlSplit.length === 2) {
    return language === 'en'
      ? `${cleanUrlSplit[0]}?${cleanUrlSplit[1]}`
      : `${cleanUrlSplit[0]}/${language}?${cleanUrlSplit[1]}`;
  }

  return language === 'en'
    ? `${cleanUrlSplit[0]}`
    : `${cleanUrlSplit[0]}/${language}`;
}

export default getI18nLabel;