import React, { useEffect, useRef, useState } from 'react';
import camelCase from 'lodash/camelCase';
import classNames from 'classnames';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import getI18nLabel from '../../utils/getI18nLabel';

const defaultStyle = {
  listStyleType: 'none',
  height: 'auto',
  margin: 0,
  overflow: 'hidden',
  padding: 0,
  transition: `height 500ms ease-out`
};

const getTransitionStyles = h => ({
  entering: { height: 0 },
  entered: { height: h },
  exiting: { height: h },
  exited: { height: 0 }
});

const MenuItem = props => {
  const { activeNode, children, name, labelName, language, noLabel } = props;
  const label = isNull(labelName) ? camelCase(name) : labelName;
  const subMenuRef = useRef(null);
  const heightRef = useRef(0);
  const [isOpened, toggleOpen] = useState(true);
  const [mounted, setMount] = useState(false);
  const handleToggle = () => {
    toggleOpen(!isOpened);
  };
  useEffect(() => {
    if (!isNull(subMenuRef.current)) {
      heightRef.current = subMenuRef.current.offsetHeight;
      setMount(true);
      if (activeNode !== name) {
        toggleOpen(false);
      }
    }
  }, [activeNode, name]);
  const getLabel = getI18nLabel(language);
  return (
    <li
      className={classNames('phillips__nav__off-canvas__item', {
        'phillips__nav__off-canvas__item--selected': activeNode === name
      })}
    >
      <button
        className={classNames('phillips__nav__off-canvas__item__button', {
          'phillips__nav__off-canvas__item__button--opened': isOpened
        })}
        onClick={handleToggle}
        type="button"
        value={name}
      >
        {noLabel ? label : getLabel({ label: label })}
      </button>
      <Transition in={isOpened} timeout={0}>
        {state => (
          <ul
            name={name}
            ref={subMenuRef}
            style={{
              ...defaultStyle,
              ...(mounted ? getTransitionStyles(heightRef.current)[state] : {})
            }}
          >
            {children}
          </ul>
        )}
      </Transition>
    </li>
  );
};

MenuItem.defaultProps = {
  activeNode: null,
  labelName: null
};

MenuItem.propTypes = {
  activeNode: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
      ])
    )
  ]).isRequired,
  labelName: PropTypes.string,
  name: PropTypes.string.isRequired
};
export default MenuItem;
