import React from 'react';
import { AuthState } from '@phillipsauctionhouse/auth-js/types';
import getAuth from './getAuth';

const isCSR = typeof window !== 'undefined';

const useAuth = () => {
  const [auth, setAuth] = React.useState(isCSR ? window.auth : undefined);
  const [authState, setAuthState] = React.useState(
    auth ? auth.state : AuthState.Initialising
  );

  React.useEffect(() => {
    (async () => {
      setAuth(await getAuth());
    })();
  }, []);

  React.useEffect(() => {
    if (auth) { 
      auth.registerAuthStateListener(newAuthState =>
        setAuthState(oldAuthState =>
          oldAuthState !== newAuthState ? newAuthState : oldAuthState
        )
      );
      return () => {
        auth.removeAuthStateListener(setAuthState);
      }
    }
  }, [auth]);

  return {
    login: auth && auth.login,
    loginAsPhillipsEmployee: auth && auth.loginAsPhillipsEmployee,
    getToken: auth && auth.getToken,
    changePassword: auth && auth.changePassword,
    logout: auth && auth.logout,
    authState
  };
};

export default useAuth;
