import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PhillipsTranslations from '../PhillipsTranslations/PhillipsTranslations';
import uriEncoder from '../../utils/uriencoder';

const MakerItem = props => {
  const makerNameHtml = props.getTranslatedString('makerName').toUpperCase().replace(props.query.toUpperCase(), '<strong>$&</strong>');
  return (
    <a
      className={classNames("react-autosuggest__suggestion__link", {
        'react-autosuggest__suggestion__link--highlighted': props.isHighlighted
      })}
      href={props.getI18nLink(`/artist/${props.makerId}/${uriEncoder(props.makerName)}`)}
      dangerouslySetInnerHTML={{ __html: makerNameHtml }}
    />
  )
};

MakerItem.defaultProps = {
  makerId: null
}

MakerItem.propTypes = {
  getI18nLink: PropTypes.func.isRequired,
  getTranslatedString: PropTypes.func.isRequired,
  makerId: PropTypes.number,
  isHighlighted: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  makerName: PropTypes.string.isRequired
}
export default PhillipsTranslations(MakerItem);