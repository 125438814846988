const routes = {
  ROUTES_DEFAULT: '/',
  ROUTES_LANGUAGE: '/:language',
  ROUTES_FILTER: '/feed/filter/:filter',
  ROUTES_FILTER_LANGUAGE: '/feed/filter/:filter/:language',
  ROUTES_INIT: '/feed',
  ROUTES_INIT_LANGUAGE: '/feed/:language',
  ROUTES_SORT: '/feed/sort/:sort',
  ROUTES_SORT_LANGUAGE: '/feed/sort/:sort/:language',
  ROUTES_FILTERSORT: '/feed/filter/:filter/sort/:sort',
  ROUTES_FILTERSORT_LANGUAGE: '/feed/filter/:filter/sort/:sort/:language'
};

const getRoutes = location => {
  return {
    routes,
    options: {
      basename: '/editorial',
      initialEntries: [location],
      initialDispatch: false
    }
  };
};

export default getRoutes;
