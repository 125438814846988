/* eslint-disable import/prefer-default-export */

// AuctionPage
export const language = (state = 'en', { type, payload }) => {
  switch (type) {
    case 'ROUTES_DEFAULT':
    case 'ROUTES_FILTERSORT':
    case 'ROUTES_FILTER':
    case 'ROUTES_SORT':
      return state === 'en' ? state : 'en';
    case 'ROUTES_LANGUAGE':
    case 'ROUTES_FILTERSORT_LANGUAGE':
    case 'ROUTES_FILTER_LANGUAGE':
    case 'ROUTES_SORT_LANGUAGE':
      return state === payload.language ? state : payload.language;
    default:
      return state;
  }
};
/*
// LotPage
export const currentLanguage = (state = 'en-US', { type, payload }) => {
  let nextState = '';
  switch (type) {
     {
    nextState = payload.language;
    break;
  }
    default: {
  nextState = state;
}
  }
return nextState;
};

// Consignments
const checkLang = (lang) => {
  const language = lang.toUpperCase() === 'CH' ? 'ch' : 'en';
  return language;
}

export const language = (state = '', { type, payload }) => {
  switch (type) {
    default:
      return state;
  }
} */