import React from 'react';
import PropTypes from 'prop-types';
import reduce from 'lodash/reduce';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import upperFirst from 'lodash/upperFirst';
import setI18nLanguage, { setI18nLinkLanguage } from '../../utils/getI18nLabel';

/*
  In our current lot model all chinese translations keys are prefixed with a c.
  This Higher Order Component provides its component with a getTranslatedString method
  which return the correct text from a property.
*/

const PhillipsTranslations = (Component, innerProperty = null) => props => {
  const realProps = !isNull(innerProperty) ? props[innerProperty] : props;
  const translationStrings = reduce(
    realProps,
    (result, value, key) => {
      const res = result;
      // find chinese translation using our convention ex: circa => cCirca;
      let chineseTranslation = realProps[`c${upperFirst(key)}`];
      // only add property to translationString if chinese translation exists
      if (!isUndefined(chineseTranslation)) {
        if (!isNull(chineseTranslation) && chineseTranslation.length === 0) {
          chineseTranslation = null;
        }
        res[key] = { en: value, zh: chineseTranslation };
      } else {
        res[key] = { en: value };
      }
      return res;
    },
    {}
  );
  const { language } = props;
  const getI18nLabel = setI18nLanguage(language);
  const getI18nLink = setI18nLinkLanguage(language);
  const getTranslatedString = key => {
    let translatedString;
    const translations = translationStrings[key];
    if (isUndefined(translations)) {
      translatedString = !isNull(innerProperty) ? props[innerProperty][key] : props[key];
      if (isUndefined(translatedString)) {
        console.warn(`component has no such property: ${key}`);
        translatedString = '';
      }
    } else {
      translatedString = translations[language];
      if (isNull(translatedString) || isUndefined(translatedString)) {
        // default to english if translation is undefined or null;
        translatedString = translations.en;
      }
    }
    return translatedString;
  };
  return (
    <Component
      {...props}
      getI18nLabel={getI18nLabel}
      getI18nLink={getI18nLink}
      getTranslatedString={getTranslatedString}
    />
  );
};

PhillipsTranslations.propTypes = {
  language: PropTypes.string.isRequired
};

export default PhillipsTranslations;
