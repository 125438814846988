import MakerService from '../../services/MakerService';

const makerService = new MakerService();

const searchMakerPending = payload => ({
  type: 'SEARCH_MAKER_PENDING',
  payload
})

export const searchMakerClear = () => ({
  type: 'SEARCH_MAKER_CLEAR'
})

export const searchMakerSuccess = payload => ({
  type: 'SEARCH_MAKER_SUCCESS',
  payload
})

const searchMakerError = err => ({
  type: 'SEARCH_MAKER_ERROR',
  err
});

const searchMaker = (searchQuery) => (dispatch) => {
  dispatch(searchMakerPending(searchQuery));
  makerService.search(searchQuery)
    .then(json => dispatch(searchMakerSuccess(json.makers)))
    .catch((err) => {
      console.error('SearchMakerError: ', err);
      dispatch(searchMakerError(err));
    });
};

export default searchMaker;