import homePageRoutes from '../HomePage/routes';
import auctionPageRoutes from '../AuctionPage/routes';
import browseArtistsRoutes from '../BrowseArtists/routes';
import lotPageRoutes from '../LotPage/routesMap';
import getGalleryOneRoutes from '../MarketPlace/routes';
import getPrivateSalesRoutes from '../BuyNow/routes';
import getVoicesRoutes from '../Editorial/Hub/Feed/routesMap';

const defaultRoutes = {
  ROUTES_DEFAULT: '/',
  ROUTES_LANGUAGE: '/:language'
};

export const hasLanguageInUrl = /(\/zh\/?)$/g;

const getRoutes = location => {
  const homepageBasenames = ['/', '/zh'];
  const auctionPageBase = '/auctions/';
  const lotPageBase = ['/detail/'];
  const browseArtistsBase = ['/artists/'];
  const [defaultBaseUrl] = location.split(hasLanguageInUrl);
  switch (location) {
    case location.indexOf(browseArtistsBase) > -1:
      return {
        routes: browseArtistsRoutes,
        options: {
          basename: '/artists',
          initialEntries: [location],
          initialDispatch: false
        }
      };
    case location.indexOf(auctionPageBase) > -1:
      return {
        routes: auctionPageRoutes,
        options: {
          basename: '/auctions',
          initialEntries: [location],
          initialDispatch: false
        }
      };
    case location.indexOf(lotPageBase) > -1:
      return {
        routes: lotPageRoutes,
        options: {
          basename: location,
          initialEntries: [location],
          initialDispatch: false
        }
      };
    case homepageBasenames.indexOf(location) > -1:
      return {
        routes: homePageRoutes,
        options: {
          basename: '/',
          initialEntries: [location],
          initialDispatch: false
        }
      };
    case location.indexOf('/store/') > -1:
      return getPrivateSalesRoutes(location);
    case location.indexOf('galleryone') > -1:
      return getGalleryOneRoutes(location);
    case location.indexOf('/editorial') > -1:
      return getVoicesRoutes(location);
    default:
      return {
        routes: defaultRoutes,
        options: {
          basename: defaultBaseUrl,
          initialEntries: [location],
          initialDispatch: false
        }
      };
  }
};

export default getRoutes;
