import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ArtistSearch from '../ArtistSearch';
import PhillipsTranslations from '../PhillipsTranslations/PhillipsTranslations';

const PhillipsSearch = ({ isOpen, getI18nLink }) => (
  <aside aria-hidden={!isOpen} className={classNames("phillips__nav__off-canvas-wrapper phillips__nav__off-canvas-wrapper--right", { 'phillips__nav__off-canvas-wrapper--open phillips__nav__off-canvas-wrapper--open--right': isOpen })}>
    <ArtistSearch
      getI18nLink={getI18nLink}
      actionUrl={getI18nLink("/Search")}
      isOpen={isOpen}
    />
  </aside>
);

PhillipsSearch.propTypes = {
  getI18nLink: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default PhillipsTranslations(PhillipsSearch);