import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import isNull from 'lodash/isNull';
import kebabCase from 'lodash/kebabCase';
import Autosuggest from 'react-autosuggest';
import { useSelector, useDispatch } from 'react-redux';
import searchMaker, { searchMakerSuccess } from './actions';
import MakerItem from './MakerItem';
import getI18nLabel, { setI18nLinkLanguage } from '../../utils/getI18nLabel';
import { hasForbiddenChars } from '../utils/search';

const SearchList = ({ containerProps, children, language, query }) => (
  <div {...containerProps}>
    {children}
    {!isNull(children) && !/[^a-z0-9 _-]{5,}/g.test(query) ? (
      <a
        className="react-autosuggest__suggestion__link"
        href={`${setI18nLinkLanguage(language)('/Search')}?Search=${query}`}
      >
        View all results for&nbsp;
        <strong>{query}</strong>
      </a>
    ) : null}
  </div>
);

SearchList.defaultProps = {};

SearchList.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  children: PropTypes.shape().isRequired,
  containerProps: PropTypes.shape().isRequired,
  language: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired
};

const Search = ({ actionUrl, isOpen }) => {
  const inputRef = useRef(null);
  const windowRef = useRef(null);
  const { language, searchOptions } = useSelector(state => state);
  const dispatch = useDispatch();
  const [currentValue, setInputValue] = useState('');
  const [highlighted, setHighlighted] = useState(null);
  useEffect(() => {
    if (window) {
      windowRef.current = window;
    }
  }, []);
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }
    if (window) {
      windowRef.current = window;
    }
  }, [isOpen]);
  const getLabel = getI18nLabel(language);
  const getI18nLink = setI18nLinkLanguage(language);
  const getSuggestionValue = maker =>
    language === 'en' ? maker.makerName : maker.cMakerName || maker.makerName;
  const renderSuggestion = (suggestion, { query, isHighlighted }) => (
    <MakerItem
      {...suggestion}
      language={language}
      query={query}
      isHighlighted={isHighlighted}
      getI18nLink={getI18nLink}
    />
  );
  const handleInputChange = (event, { newValue }) => {
    setInputValue(newValue);
    if (hasForbiddenChars(newValue)) {
      event.target.setCustomValidity(
        'Please enter a search term without special characters.'
      );
    } else {
      event.target.setCustomValidity('');
    }
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (!isNull(highlighted)) {
      windowRef.current.location.assign(
        `/artist/${highlighted.makerId}/${kebabCase(highlighted.url)}`
      );
    } else {
      const formData = serialize(e.target);
      // eslint-disable-next-line no-unused-expressions
      e.target[0].value.length >= 2
        ? windowRef.current.location.assign(`${actionUrl}?${formData}`)
        : null;
    }
  };
  const inputProps = {
    className: 'phillips__nav__search__input',
    name: 'Search',
    onChange: handleInputChange,
    placeholder: getLabel({ label: 'search' }),
    value: currentValue,
    maxLength: 35
  };
  const renderInputComponent = props => <input {...props} ref={inputRef} />;
  const onRenderSuggestionsContainer = props => (
    <SearchList {...props} language={language} />
  );
  return (
    <form className="phillips__nav__search" onSubmit={handleSubmit}>
      <Autosuggest
        suggestions={searchOptions}
        inputProps={inputProps}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={(e, { suggestion }) => {
          if (suggestion.makerId === 0) {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
          setHighlighted(suggestion);
        }}
        onSuggestionsFetchRequested={({ value, reason }) => {
          // The regex needs to be within this scope in order to work correctly
          const regex = /[^-\p{L}\p{N}\p{M}\s]+/gu;
          if (
            reason === 'input-changed' &&
            !regex.test(value) &&
            value.replace(regex, ' ').length > 2
          ) {
            dispatch(searchMaker(value.replace(regex, ' ')));
          }
        }}
        onSuggestionsClearRequested={() => searchMakerSuccess([])}
        renderInputComponent={renderInputComponent}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={onRenderSuggestionsContainer}
      />
      <input type="submit" className="phillips__nav__search__submit" />
    </form>
  );
};

Search.defaultProps = {};

Search.propTypes = {
  actionUrl: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default Search;
