/* eslint-disable no-console */
/* global ga fbq */
import AnalyticsService from '../services/AnalyticsService';

const analyticsService = new AnalyticsService();

const getCAPIEventName = (eventAction = '') => {
  switch (eventAction) {
    case 'Favorite Lot':
      return 'client_portal__favoriteLot_click';
    case 'Follow Maker':
      return 'client_portal__followMaker_success';
    case 'Submitted Consignment':
      return 'submitted_consignment';
    case 'Advance Bid Clicks':
      return 'place_advance_bid';
    case 'Newsletter Sign-Ups':
      return 'newsletter_subscribe';
    default:
      return null;
  }
};

const getFbqEventName = (eventAction = '') => {
  switch (eventAction) {
    case 'Advance Bid Clicks':
      return 'place_advance_bid';
    case 'Inquiry Submission - Flawless':
      return '<Flawless_Submission>';
    case 'Inquiry Submission - Private Sales':
      return '<Private_Sales_Inquiry_Submission>';
    case 'Inquiry Submission - Perpetual':
      return '<Perpetual_Inquiry_Submission>';
    case 'Inquire - Flawless':
      return '<Flawless_Inquire>';
    case 'Inquire - Private Sales':
      return '<Private_Sales_Inquire>';
    case 'Inquire - Perpetual Store':
      return '<Perpetual_Inquire>';
    case 'Signed Up':
      return '<Account_Creation>';
    case 'Favorite Lot':
      return 'client_portal__favoriteLot_click';
    case 'Follow Maker':
      return 'client_portal__followMaker_success';
    case 'Logged In':
      return '<Logged_In>';
    case 'Submitted Consignment':
      return 'submitted_consignment';
    case 'newsletter subscribe':
    case 'Newsletter Sign-Ups':
      return 'newsletter_subscribe';
    default:
      return '';
  }
};


function sendAnalytics({ eventCategory, eventAction, eventLabel, eventValue = 0, userEmail = ""}) {

  if (typeof gtag == 'function') { 
  //console.log("Event Action", eventAction, eventCategory, eventLabel, eventValue);
      gtag('event', eventAction, { eventCategory, eventLabel, eventValue });
  }
  if (typeof fbq !== 'undefined' && getFbqEventName(eventAction).length > 0) {
    const contentLabel = getFbqEventName(eventAction) === '<Account_Creation>' ? 'N/A' : eventLabel;
    fbq('trackCustom', getFbqEventName(eventAction), {
      content_category: eventCategory,
      content_name: eventAction,
      content_label: contentLabel
    });
  }
  //FB CAPI tracking code
  const eventName = getCAPIEventName(eventAction);
  if (eventName) {
    analyticsService.trackActivity({
      "data": [
        {
          "event_name": eventName,
          "event_time": Date.now(),
          "action_source": "website",
          "user_data": {
            "em": [
              userEmail
            ]
          }
        }
      ]
    })
  }

}

export default sendAnalytics;
