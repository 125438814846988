import isUndefined from 'lodash/isUndefined';
import apiSettings from '../utils/ApiSettings.decorator';
import handleResponse from '../utils/handleresponse';
import { getAuth } from '../react/Auth';

@apiSettings
class UserService {
  // constructor () {
  //   // listen to auth state and if logged in with utm data in session, send it to the API
  // }

  async fetchUser() {
    const auth = await getAuth();
    const token = await auth.getToken();
    const url = `${this.apiDomain}api/user/${await auth.getUserId()}`;
    const options = {
      headers: {
        Authorization: `bearer ${token}`
      }
    };
    return fetch(url, options).then(handleResponse);
  }

  // CMS user

  async fetchCMSUser() {
    const auth = await getAuth();
    const token = await auth.getToken();
    const url = `${this.cmsApiDomain}/users/setUser?id=${await auth.getUserId()}`;
    const options = {
      headers: {
        Authorization: `bearer ${token}`
      }
    };
    return fetch(url, options).then(handleResponse);
  }

  logoff() {
    const url = `${this.cmsApiDomain}users/Logoff`;
    const options = {
      method: 'POST'
    };
    return fetch(url, options).then(handleResponse);
  }

  async logoutCMSUser() {
    const auth = await getAuth();
    return auth.logout(window?.location.pathname);
  }

  async resetUserPassword() {
    const auth = await getAuth();
    return auth.changePassword(window.location.pathname); // also make sure we capture search e.g. ?artObject=1
  }

  async editUser({ payload }) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const url = `${this.apiDomain}api/user/${await auth.getUserId()}`;
    const options = {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8'
      }
    };
    return fetch(url, options).then(handleResponse);
  }

  async editAddress({ payload }) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const url = `${this.apiDomain}api/user/${await auth.getUserId()}`;
    const options = {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8'
      }
    };
    return fetch(url, options).then(handleResponse);
  }

  async editPreferences({ payload }) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const url = `${this.apiDomain}api/user/${await auth.getUserId()}/emailpreferences`;
    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8'
      }
    };
    return fetch(url, options).then(handleResponse);
  }

  async deleteLotFromList(userId, listId, lot) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(lot)
    };

    return fetch(
      `${this.apiDomain}api/user/${userId}/lotlist/${listId}/item`,
      options
    ).then(handleResponse);
  }

  async saveLotToList(userId, listId, lot) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      method: 'POST',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(lot)
    };

    return fetch(
      `${this.apiDomain}api/user/${userId}/lotlist/${listId}/item`,
      options
    ).then(handleResponse);
  }

  async deleteLotList(userId, listId) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      method: 'DELETE',
      headers: { Authorization: `bearer ${token}` }
    };

    return fetch(
      `${this.apiDomain}api/user/${userId}/lotlist/${listId}`,
      options
    ).then(handleResponse);
  }

  async saveLotList(userId, list) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      method: 'POST',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(list)
    };
    return fetch(
      `${this.apiDomain}api/user/${userId}/lotlist/${list.id || 0}`,
      options
    ).then(handleResponse);
  }

  async saveLot(userId, { saleNumber, lotNumber }) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      method: 'POST',
      headers: { Authorization: `bearer ${token}` }
    };

    return fetch(
      `${this.apiDomain}api/user/${userId}/favoriteLot/${saleNumber}-${lotNumber}`,
      options
    ).then(handleResponse);
  }

  async deleteLot(userId, { saleNumber, lotNumber }) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      method: 'DELETE',
      headers: { Authorization: `bearer ${token}` }
    };

    return fetch(
      `${this.apiDomain}api/user/${userId}/favoriteLot/${saleNumber}-${lotNumber}`,
      options
    ).then(handleResponse);
  }

  async saveMaker(userId, makerId) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      method: 'POST',
      headers: { Authorization: `bearer ${token}` }
    };

    return fetch(
      `${this.apiDomain}api/user/${userId}/followmaker/${makerId}`,
      options
    ).then(handleResponse);
  }

  async deleteMaker(userId, makerId) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      method: 'DELETE',
      headers: { Authorization: `bearer ${token}` }
    };

    return fetch(
      `${this.apiDomain}api/user/${userId}/followmaker/${makerId}`,
      options
    ).then(handleResponse);
  }

  async fetchArtistLotList(id) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      headers: { Authorization: `bearer ${token}` }
    };
    return fetch(
      `${this.apiDomain}api/maker/${id}//lots?resultsPerPage=5`,
      options
    ).then(handleResponse);
  }

  async fetchUserDetails() {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      headers: { Authorization: `bearer ${token}` }
    };

    return fetch(
      `${this.apiDomain}api/user/${await auth.getUserId()}/details`,
      options
    ).then(handleResponse);
  }

  async fetchRecommendedLots(id, saleNumber) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      headers: { Authorization: `bearer ${token}` }
    };
    // if saleNumber is undefined fetch all recommended lots
    const saleNumberParam = isUndefined(saleNumber) ? '' : `/${saleNumber}`;
    return fetch(
      `${this.apiDomain}api/user/${id}/recommendedlots${saleNumberParam}`,
      options
    ).then(handleResponse);
  }

  async fetchUserEmailPrefs(id) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      headers: { Authorization: `bearer ${token}` }
    };
    return fetch(
      `${this.apiDomain}api/user/${id}/emailpreferences`,
      options
    ).then(handleResponse);
  }

  async getCountryList() {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      headers: { Authorization: `bearer ${token}` }
    };

    return fetch(`${this.apiDomain}api/lookup/countries`, options).then(
      handleResponse
    );
  }

  async getStatesList() {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      headers: { Authorization: `bearer ${token}` }
    };

    return fetch(`${this.apiDomain}api/lookup/states`, options).then(
      handleResponse
    );
  }

  async getMessageCategories() {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      headers: { Authorization: `bearer ${token}` }
    };

    return fetch(`${this.apiDomain}api/lookup/messagecategories`, options).then(
      handleResponse
    );
  }
  // perpetual config code

  getPerpetualConfig() {
    const options = {
      method: 'POST',
      headers: {
        Authorization: this.authToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(['PerpetualSaleNumber'])
    };
    return fetch(`${this.apiDomain}api/lookup/getconfigurablevalues`, options).then(handleResponse);
  }

  async logout() {
    const auth = await getAuth();
    sessionStorage.removeItem('utmData');
    if (phillips && typeof phillips.user !== 'undefined') {
      phillips.user.trigger('logout');
    }
    if (
      window.location.pathname.includes('/account')
    ) {
      // we are logging out from a protected page, so redirect to home
      return auth.logout(window.location.origin);
    }
    return auth.logout();
  }

  async resetUserPassword() {
    const auth = await getAuth();
    return auth.changePassword(window.location.pathname); // also make sure we capture search e.g. ?artObject=1
  }

  async trackUserActivity(activityModel) {
    const auth = await getAuth();
    const token = await auth.getToken();
    const options = {
      method: 'POST',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(activityModel)
    };

    return fetch(
      `${this.apiDomain}api/user/${activityModel.userId}/saveuseractivity`,
      options
    ).then(handleResponse);
  }
}

export default UserService;