import PropTypes from 'prop-types';

// BEGIN FLOCKLER EDITORIAL PROP TYPES
const editorialSectionPropTypes = {
  id: PropTypes.number,
  name: PropTypes.string
};

const defaultEditorialProps = {
  articleUrl: '',
  associatedSalesList: [],
  coverUrl: '',
  publishDate: '',
  section: '',
  sectionId: 0,
  sections: [],
  summary: '',
  title: '',
  flocklerId: 0,
  state: 0,
  displayType: 0,
  alternateTitle: '',
  alternateDescription: '',
  makerId: null
};
const baseEditorialPropTypes = {
  articleUrl: PropTypes.string,
  coverUrl: PropTypes.string,
  publishDate: PropTypes.string,
  section: PropTypes.string,
  summary: PropTypes.string,
  title: PropTypes.string
};
const editorialPropTypes = {
  ...baseEditorialPropTypes,
  associatedSalesList: PropTypes.arrayOf(PropTypes.string),
  sectionId: PropTypes.number,
  sections: PropTypes.arrayOf(PropTypes.shape(editorialSectionPropTypes)),
  associatedSales: PropTypes.string,
  flocklerId: PropTypes.number,
  state: PropTypes.number,
  displayType: PropTypes.number,
  alternateTitle: PropTypes.string,
  alternateDescription: PropTypes.string,
  makerId: PropTypes.number
};
// END FLOCKLER EDITORIAL PROP TYPES

const featuredMakerPropType = {
  biography: PropTypes.string,
  birthYear: PropTypes.string,
  deathYear: PropTypes.string,
  firstQuote: PropTypes.string,
  imagePath: PropTypes.string,
  isConsignmentMaker: PropTypes.bool,
  isFeatured: PropTypes.bool,
  landingDescription: PropTypes.string,
  lotNumber: PropTypes.number,
  lotNumberSuffix: PropTypes.string,
  makerId: PropTypes.number,
  makerName: PropTypes.string,
  nationality: PropTypes.string,
  saleNumber: PropTypes.string,
  secondQuote: PropTypes.string
};

const makerEditorialPropType = {
  birthYear: PropTypes.string,
  deathYear: PropTypes.string,
  editorial: editorialPropTypes,
  makerId: PropTypes.number,
  makerName: PropTypes.string,
  nationality: PropTypes.string
};

const makerPropTypes = {
  makerName: PropTypes.string,
  makerId: PropTypes.number,
  artistBiography: PropTypes.string,
  artistBirthYear: PropTypes.string,
  artistDeathYear: PropTypes.string,
  artistNationality: PropTypes.string
};

const nftPropTypes = {
  nftTokenNumber: PropTypes.number,
  nftTokenID: PropTypes.string,
  nftContractAddress: PropTypes.string,
  nftTokenType: PropTypes.string,
  nftImageInfo: PropTypes.string,
  nftVideoInfo: PropTypes.string,
  nftMintingInfo: PropTypes.string,
  cNftNote: PropTypes.string,
  cNftContractAddress: PropTypes.string,
  cNftTokenType: PropTypes.string,
  cNftImageInfo: PropTypes.string,
  cNftVideoInfo: PropTypes.string,
  cNftMintingInfo: PropTypes.string
};

const auctionPropTypes = {
  auctionMobilityAuctionRowId: PropTypes.string,
  auctionBidPartner: PropTypes.number.isRequired,
  auctionDetails: PropTypes.string,
  auctionDetailsSmall: PropTypes.string,
  auctionPdfUrl: PropTypes.string,
  auctionTitle: PropTypes.string,
  cAuctionPdfUrl: PropTypes.string,
  cAuctionTitle: PropTypes.string,
  cAuctionDetails: PropTypes.string,
  conditionRequestEmail: PropTypes.string,
  departmentName: PropTypes.string,
  enableCuratedAuction: PropTypes.bool,
  enableOnlineCatalogue: PropTypes.bool,
  endDate: PropTypes.string,
  eventDate: PropTypes.string,
  extraInfo: PropTypes.string,
  locationName: PropTypes.string,
  saleNumber: PropTypes.string,
  saleTypeID: PropTypes.number,
  showAdvanceBidButton: PropTypes.bool,
  showArtCollector: PropTypes.bool,
  showRegistrationLink: PropTypes.bool,
  startDate: PropTypes.string,
  timeState: PropTypes.number,
  useCloudinary: PropTypes.bool
};

const lotDescPropTypes = {
  circa: PropTypes.string,
  artistInscription: PropTypes.string,
  medium: PropTypes.string,
  dimensions: PropTypes.string,
  sigEdtMan: PropTypes.string
};

const lotImagePropType = {
  imagePath: PropTypes.string,
  isViewInRoom: PropTypes.bool
};

const watchDescPropTypes = {
  wReferenceNo: PropTypes.string,
  wMovementNo: PropTypes.string,
  wCaseNo: PropTypes.string,
  wModelName: PropTypes.string,
  wMaterial: PropTypes.string,
  wCalibre: PropTypes.string,
  wBracelet_Strap: PropTypes.string,
  wClasp_Buckle: PropTypes.string,
  wAccessories: PropTypes.string
};

const jewelsDescPropTypes = {
  jReport: PropTypes.string,
  jPrincipalStone: PropTypes.string,
  jSide: PropTypes.string,
  jMetal: PropTypes.string,
  jAssayMarks: PropTypes.string,
  jRemark: PropTypes.string,
  jYear: PropTypes.string,
  jAccessories: PropTypes.string
};

const lotPropTypes = {
  artistImageBlocked: PropTypes.bool,
  auctionLotDisplayTypeId: PropTypes.number,
  auctionLotDisplayTypeName: PropTypes.string,
  auctionLotPublicId: PropTypes.number,
  auctionMobilityAuctionRowId: PropTypes.string,
  auctionMobilityLotRowId: PropTypes.string,
  buyNowPrice: PropTypes.number,
  buyNowSaleNumber: PropTypes.string,
  className: PropTypes.string,
  cloudinaryVersion: PropTypes.string,
  currencySign: PropTypes.string,
  customImageSrc: PropTypes.string,
  description: PropTypes.string,
  descriptionWithMarkup: PropTypes.string,
  detailLink: PropTypes.string,
  detailVideoUrl: PropTypes.string,
  disableFetchRowId: PropTypes.bool,
  disableFollow: PropTypes.bool,
  editable: PropTypes.bool,
  enableArtCollector: PropTypes.bool,
  enableShare: PropTypes.bool,
  enableTrackVisibility: PropTypes.bool,
  endSale: PropTypes.bool,
  essay: PropTypes.string,
  estimateSpecialChar: PropTypes.string,
  estimateText: PropTypes.string,
  eventDate: PropTypes.string,
  eventTime: PropTypes.string,
  extraInfo: PropTypes.string,
  filterEnabled: PropTypes.bool,
  hammerPlusBP: PropTypes.number,
  hammerPlusBPLive: PropTypes.number,
  hasRouter: PropTypes.bool,
  hideUserActions: PropTypes.bool,
  hideEstimate: PropTypes.bool,
  highEstimate: PropTypes.number.isRequired,
  imagePath: PropTypes.string,
  imageTransformation: PropTypes.string,
  is360View: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isExhibition: PropTypes.bool,
  isOnlineSale: PropTypes.bool,
  isLot: PropTypes.bool,
  isMixedAuction: PropTypes.bool,
  isNoLot: PropTypes.bool,
  isNoReserve: PropTypes.bool,
  language: PropTypes.string,
  isSoldOverride: PropTypes.bool,
  isHideDisplay: PropTypes.bool,
  isVisible: PropTypes.bool,
  isWatch: PropTypes.bool,
  ...jewelsDescPropTypes,
  key: PropTypes.string,
  lazyLoadOffset: PropTypes.number,
  locationName: PropTypes.string,
  lotListDisabled: PropTypes.bool,
  lotNumber: PropTypes.number,
  lotNumberFull: PropTypes.string,
  lotNumberSuffix: PropTypes.string,
  lotImages: PropTypes.arrayOf(PropTypes.shape(lotImagePropType)),
  lotStatusId: PropTypes.number,
  lowEstimate: PropTypes.number.isRequired,
  ...makerPropTypes,
  ...nftPropTypes,
  objectNumber: PropTypes.string,
  saleNumber: PropTypes.string,
  saleTitle: PropTypes.string,
  saleTypeId: PropTypes.number,
  showBidButton: PropTypes.bool,
  showBidButtonPlaceholder: PropTypes.bool,
  showCuratedView: PropTypes.bool,
  showEstimateText: PropTypes.bool,
  showLotNumber: PropTypes.bool,
  showSoldPrice: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.object),
  timeState: PropTypes.number,
  toggleEstHammer: PropTypes.bool,
  useCloudinary: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  isViewInRoom: PropTypes.bool,
  literature: PropTypes.string,
  exhibited: PropTypes.string,
  videoSource: PropTypes.string,
  videoTitle: PropTypes.string,
  videoDescription: PropTypes.string,
  webDescription: PropTypes.string,
  ...lotDescPropTypes,
  lotSpecialChar: PropTypes.string,
  ...watchDescPropTypes
};

const defaultLotProps = {
  artistImageBlocked: false,
  auctionLotDisplayTypeId: 1,
  auctionLotDisplayTypeName: 'single-cell',
  auctionLotPublicId: 0,
  auctionMobilityAuctionRowId: '0',
  auctionMobilityLotRowId: '0',
  buyNowPrice: 0,
  buyNowSaleNumber: 'EX080123',
  className: '',
  cloudinaryVersion: '1',
  currencySign: '$',
  customImageSrc: '',
  language: 'en',
  description: '',
  descriptionWithMarkup: null,
  detailLink: '',
  detailVideoUrl: null,
  disableFetchRowId: false,
  disableFollow: false,
  editable: false,
  enableArtCollector: false,
  enableShare: false,
  enableTrackVisibility: false,
  endSale: false,
  estimateSpecialChar: '',
  estimateText: '',
  filterEnabled: true,
  hammerPlusBP: 0,
  hasRouter: false,
  hideUserActions: false,
  hideEstimate: false,
  highEstimate: 1,
  imagePath: '',
  imageTransformation: 'SingleCell',
  is360View: false,
  isDesktop: true,
  isExhibition: false,
  isHideDisplay: false,
  isLot: true,
  isMixedAuction: false,
  isNoLot: false,
  isNoReserve: false,
  jAccessories: null,
  jAssayMarks: null,
  jMetal: null,
  jRemark: null,
  jReport: null,
  jSide: null,
  jYear: null,
  lazyLoadOffset: 500,
  locationName: 'New York',
  lotListDisabled: false,
  lotNumber: 1,
  lotNumberFull: '1 ',
  lotNumberSuffix: ' ',
  lotStatusId: 1,
  lowEstimate: 0,
  makerName: '',
  makerId: 0,
  objectNumber: '',
  saleNumber: '',
  saleTypeId: 1,
  showBidButton: false,
  showBidButtonPlaceholder: false,
  showCuratedView: false,
  showEstimateText: true,
  showLotNumber: true,
  showSaleOffers: false,
  showSoldPrice: true,
  tags: [],
  timeState: 0,
  toggleEstHammer: false,
  useCloudinary: true,
  videoDescription: '',
  videoSource: '',
  videoTitle: '',
  wModelName: null,
  wReferenceNo: null
};
const defaultUserProps = {
  recommendedLots: [],
  followedMakers: [],
  favoriteLots: [],
  lotLists: [],
  saleRegistrations: [],
  user: {
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    loggedIn: false,
    name: '',
    phoneCountryCode: null,
    phoneNumber: null,
    phoneNumberLocal: null,
    messageCategories: []
  },
  userForm: {
    status: '',
    message: '',
    type: 'signup'
  }
};

const saleRegistration = {
  saleNumber: PropTypes.string.isRequired,
  paddleNumber: PropTypes.number.isRequired,
  registrationStatus: PropTypes.number.isRequired
};
const messageCategoryPropType = {
  messageId: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  messageTypeId: PropTypes.number.isRequired,
  messageType: PropTypes.string.isRequired,
  categoryDescription: PropTypes.string.isRequiredd
};
const user = {
  recommendedLots: PropTypes.array,
  followedMakers: PropTypes.array,
  favoriteLots: PropTypes.array,
  lotLists: PropTypes.array,
  saleRegistrations: PropTypes.arrayOf(PropTypes.shape(saleRegistration)),
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    messageCategories: PropTypes.arrayOf(PropTypes.shape(messageCategoryPropType)),
    loggedIn: PropTypes.bool,
    phoneCountryCode: PropTypes.string,
    phoneNumber: PropTypes.string,
    phoneNumberLocal: PropTypes.string
  })
};

const videoPropType = {
  videoPath: PropTypes.string,
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
};
// START DEPT PAGE PROPTYPES
const deptAuctionPropTypes = {
  auctionPath: PropTypes.string,
  auctionTitle: PropTypes.string,
  endDate: PropTypes.string,
  locationName: PropTypes.string,
  imagePath: PropTypes.string,
  saleNumber: PropTypes.string,
  startDate: PropTypes.string,
  useCloudinary: PropTypes.bool
};
const deptPropTypes = {
  banner: PropTypes.shape({
    active: PropTypes.bool,
    imagePath: PropTypes.string,
    link: PropTypes.string
  }),
  buyNowCarousel: PropTypes.arrayOf(PropTypes.shape(lotDescPropTypes)),
  carousel: PropTypes.arrayOf(PropTypes.shape(lotPropTypes)),
  departmentId: PropTypes.number.isRequired,
  departmentName: PropTypes.string.isRequired,
  details: PropTypes.shape({
    departmentName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string
      })
    )
  }),
  features: PropTypes.arrayOf(PropTypes.shape(baseEditorialPropTypes)),
  hero: PropTypes.shape({
    active: PropTypes.bool,
    imagePath: PropTypes.string,
    buttonText: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string
  }),
  instagram: PropTypes.shape({
    account: PropTypes.string,
    photos: PropTypes.arrayOf(
      PropTypes.shape({
        imagePath: PropTypes.string,
        postUrl: PropTypes.string
      })
    )
  }),
  pastAuctions: PropTypes.arrayOf(PropTypes.shape(deptAuctionPropTypes)),
  pressReleases: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      eventDate: PropTypes.string,
      description: PropTypes.string,
      articlePath: PropTypes.string
    })
  ),
  upcomingAuctions: PropTypes.arrayOf(PropTypes.shape(deptAuctionPropTypes)),
  videos: PropTypes.arrayOf(PropTypes.shape(videoPropType))
};
// END DEPT PAGE PROPTYPES

const stickyPropType = {
  Title: PropTypes.string,
  LocationName: PropTypes.string,
  SaleNumber: PropTypes.string,
  StartDateTimeOffset: PropTypes.string,
  Date: PropTypes.string,
  cDate: PropTypes.string,
  Time: PropTypes.string,
  cTime: PropTypes.string,
  EndSale: PropTypes.string,
  Session: PropTypes.shape({
    Title: PropTypes.string,
    LotsRange: PropTypes.string,
    SessionTime: PropTypes.string,
    CSessionTime: PropTypes.string,
    SessionDate: PropTypes.string,
    CSessionDate: PropTypes.string
  }),
  TimeState: PropTypes.string,
  TimeZoneAbbr: PropTypes.string,
  TimeZone: PropTypes.string,
  AuctionMobilityAuctionRowId: PropTypes.string,
  URL: PropTypes.string
};

const statesListPropTypes = {
  countryID: PropTypes.number.isRequired,
  stateCode: PropTypes.string.isRequired,
  stateDesc: PropTypes.string.isRequired,
  stateID: PropTypes.number.isRequired
};

export {
  defaultEditorialProps,
  editorialPropTypes,
  featuredMakerPropType,
  makerEditorialPropType,
  makerPropTypes,
  lotPropTypes,
  defaultLotProps,
  lotDescPropTypes,
  auctionPropTypes,
  deptAuctionPropTypes,
  deptPropTypes,
  defaultUserProps,
  saleRegistration,
  user,
  videoPropType,
  statesListPropTypes,
  stickyPropType
};
