const searchOptions = (state = [], { type, payload }) => {
  switch (type) {
    case 'SEARCH_MAKER_PENDING':
      return [{ 'makerId': 0, 'makerName': 'Pending', 'cMakerName': '處理中' }];
    case 'SEARCH_MAKER_CLEAR':
      return [];
    case 'SEARCH_MAKER_SUCCESS':
      return payload;
    case 'SEARCH_MAKER_ERROR':
      return state.length === 0 ? state : [];
    default:
      return state;
  }
}

export default searchOptions;